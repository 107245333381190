import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { ethers } from 'ethers';
import { WalletContext } from '../../utils/providers/wallet/WalletContext';
import { ContentText } from '../../common/components';
import { typography } from '../../themes/typography';
import { baseTheme } from '../../themes/base';
import { useBreakpoints } from '../../utils/hooks/useBreakpoints';
import {
  FlexColumn, FlexRow,
} from '../../common/components/Layout';
import { BREAKPOINTS } from '../../utils/consts';
import ABI from '../Minting/contractABI.json';

const LoggedPanelStyled = styled.div`
  z-index: 3;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  width: 100%;
  height: calc(200% + 1px);
  padding: ${baseTheme.spacing.regular};
  background-color: ${baseTheme.colors.hanumanGreen};
  color: ${baseTheme.colors.snowWhite};

  @media (min-width: ${BREAKPOINTS.sm}px) {
    position: absolute;
    top: 0;
    left: 0;
    flex-flow: column nowrap;
    width: calc(200% + 1px);
  }
`;

const AddressStyled = styled(ContentText)`
  order: 3;
  width: 100%;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.sm}px) {
    order: 1;
    text-align: left;
  }
`;

const ConnectionStatus = styled(FlexColumn)`
  align-items: flex-start;
  order: 1;
  flex: 1 0 50%;

  @media (min-width: ${BREAKPOINTS.sm}px) {
    flex: auto;
    order: 2;
    margin-top: ${baseTheme.spacing.regular};
  }
`;

const DisconnectStyled = styled(FlexRow)`
  order: 2;
  flex: 1 0 50%;
  justify-content: flex-end;
  align-items: flex-start;

  @media (min-width: ${BREAKPOINTS.sm}px) {
    align-items: flex-end;
    flex: 0;
    order: 3;
    margin-top: ${baseTheme.spacing.medium};
  }
`;

export const LoggedPanel = () => {
  const {
    signer,
    setSigner,
  } = useContext(WalletContext);
  const [
    address,
    setAddress,
  ] = useState('');
  const [
    balance,
    setBalance,
  ] = useState('0');
  const { isDesktopMedium } = useBreakpoints();
  const onDisconnect = () => {
    if (setSigner) {
      setSigner(null);
    }
  };
  const onInit = useCallback(async () => {
    if (signer) {
      const signerAddress = await signer.getAddress();
      const contract = new ethers.Contract('0x2B860671E5Ea1b484793e376B3F982A12d03Cc3D', ABI, signer);
      const total = await contract.functions.balanceOf(signerAddress);

      setAddress(`${signerAddress.substring(0, 3)}...${signerAddress.substring(signerAddress.length - 3)}`);
      setBalance(total.toString());
    }
  }, [signer]);

  useEffect(() => {
    onInit();
  }, [onInit]);

  return (
    <LoggedPanelStyled>
      <AddressStyled
        fontFamily={typography.loosFont}
        letterSpacing={2}
        fontSize={isDesktopMedium ? typography.fontSize.md : typography.fontSize.mds}
      >
        {address}
      </AddressStyled>
      <ConnectionStatus>
        <ContentText
          fontFamily={typography.loosFont}
          fontSize={isDesktopMedium ? typography.fontSize.mds : typography.fontSize.xs}
          color={baseTheme.colors.accentGreen}
        >
          Connected
        </ContentText>
        <ContentText
          fontFamily={typography.chantalFont}
          fontSize={isDesktopMedium ? typography.fontSize.mds : typography.fontSize.xs}
          fontWeight={300}
          color={baseTheme.colors.accentGreen}
        >
          To wallet
        </ContentText>
        <ContentText
          fontFamily={typography.loosFont}
          fontSize={isDesktopMedium ? typography.fontSize.m : typography.fontSize.xs}
          color={+balance > 0 ? baseTheme.colors.satinBlack : baseTheme.colors.aeroBlue}
          mt="auto"
        >
          You have
          {' '}
          <ContentText color={+balance > 0 ? baseTheme.colors.snowWhite : 'inherit'}>{balance}</ContentText>
          {' '}
          {balance === '1' ? 'PFP' : 'PFPs'}
        </ContentText>
      </ConnectionStatus>
      <DisconnectStyled>
        <button
          onClick={onDisconnect}
          type="button"
        >
          <ContentText
            fontFamily={typography.loosFont}
            fontSize={isDesktopMedium ? typography.fontSize.base : typography.fontSize.xs}
            color={baseTheme.colors.satinBlack}
          >
            Disconnect
          </ContentText>
        </button>
      </DisconnectStyled>
    </LoggedPanelStyled>
  );
};
