import React from 'react';
import styled from 'styled-components';
import Footer from '../Footer/Layout';
import { PaintFaceGrid } from '../PaintFaceGrid/PaintFaceGrid';
import { FlexColumn } from '../../common/components/Layout';
import { BREAKPOINTS } from '../../utils/consts';

const MainStyled = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  min-height: 100vh;
  max-width: 1680px;
`;

const MainContent = styled(FlexColumn)`
  padding-bottom: 64px;

  @media (min-width: ${BREAKPOINTS.sm}px) {
    padding-bottom: 320px;
  }
`;

const Layout = () => (
  <MainStyled>
    <MainContent>
      <PaintFaceGrid />
    </MainContent>
    <Footer />
  </MainStyled>
);

export default Layout;
