import React, {
  ReactNode, useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'src/utils/consts';
import PaintFaceElement from './PaintFaceElement';
import { GridImage } from './GridImage';
import { MainDescGrid } from './MainDescGrid';
import { baseTheme } from '../../themes/base';
import { useBreakpoints } from '../../utils/hooks/useBreakpoints';
import { WalletContext } from '../../utils/providers/wallet/WalletContext';
import { LoggedPanel } from '../LoggedPanel';
import { GridSocialLink } from '../GridSocialLink';

const GridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
  grid-gap: 1px;

  @media (min-width: ${BREAKPOINTS.sm}px) {
    grid-template-columns: repeat(auto-fill, minmax(12%, 1fr));
  }

  @media (min-width: ${BREAKPOINTS.lg}px) {
    grid-template-columns: repeat(auto-fill, minmax(8%, 1fr));
  }
`;

const GridActionButton = styled.div`
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0 ${baseTheme.spacing.small};
  background-color: ${baseTheme.backgroundColor};
  border: none;
  cursor: pointer;

  & img {
    display: block;
    width: 100%;
  }
`;

const AvatarImage = styled.img`
  display: block;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
`;

const addGridElements = (numberOfSquares = 50) => Array.from(Array(numberOfSquares).keys());

export function PaintFaceGrid() {
  const [
    currentPfpIndex,
    setNextPfpIndex,
  ] = useState(1);
  const [
    holdModeOn,
    setHoldModeOn,
  ] = useState(false);
  const [
    splashOn,
    setSplashOn,
  ] = useState(false);
  const [
    facesErased,
    setFacesErased,
  ] = useState(0);
  const {
    isDesktopMedium,
    isTablet,
  } = useBreakpoints();
  const {
    signer,
  } = useContext(WalletContext);

  const gridElements = useMemo(() => addGridElements(isDesktopMedium ?
    120 :
    48), [isDesktopMedium]);
  const activeGridBoxes = useMemo(() => {
    const { length } = gridElements;

    return gridElements.map(index => {
      if (length - (length / 10) >= index) {
        return index;
      }

      if (length - (length / 5) < index) {
        return Math.random() < 0.5 ? index : null;
      }

      return index;
    });
  }, [gridElements]);

  const getFixedSrc = useCallback((index: number): ReactNode => {
    if (index === 1) {
      return <AvatarImage srcSet="/images/logo.png 1x, /images/logo@2x.png 2x" />;
    }

    if ((!isTablet && index === 23) ||
      (isTablet && !isDesktopMedium && index === 4) ||
      (isDesktopMedium && index === 9)
    ) {
      return (
        <GridActionButton onClick={() => {
          setSplashOn(true);
          setTimeout(() => {
            setSplashOn(false);
          }, 1000);
          setHoldModeOn(prev => !prev);
        }}
        >
          <img
            src="/images/pause.png"
            alt="Stop me!"
          />
        </GridActionButton>
      );
    }

    if ((!isTablet && index === 35) ||
      (isTablet && !isDesktopMedium && index === 40) ||
      (isDesktopMedium && index === 28)
    ) {
      return (
        <GridActionButton onClick={() => {
          setFacesErased(prev => prev + 1);
          setHoldModeOn(false);
        }}
        >
          <img
            src="/images/clear.png"
            alt="Go on!"
          />
        </GridActionButton>
      );
    }

    if ((!isTablet && index === 20) ||
      (isTablet && !isDesktopMedium && index === 15) ||
      (isDesktopMedium && index === 33)
    ) {
      return (
        <GridSocialLink
          img="/images/twitter.png"
          url="https://twitter.com/PaintFacePics"
        />
      );
    }

    if ((!isTablet && index === 11) ||
      (isTablet && !isDesktopMedium && index === 20) ||
      (isDesktopMedium && index === 43)
    ) {
      return (
        <GridSocialLink
          img="/images/telegram.png"
          url="https://t.me/+i-ZGNBDgq3EzODk0"
        />
      );
    }

    // TODO temp unavailable
    // if ((!isTablet && index === 20) ||
    //   (isTablet && !isDesktopMedium && index === 20) ||
    //   (isDesktopMedium && index === 20)
    // ) {
    //   return !signer && <ConnectWallet />;
    // }
    //
    // if ((!isTablet && index === 20) ||
    //   (isTablet && !isDesktopMedium && index === 20) ||
    //   (isDesktopMedium && index === 40)
    // ) {
    //   return <Minting />;
    // }

    if ((!isTablet && index === 12) ||
      (isTablet && !isDesktopMedium && index === 24) ||
      (isDesktopMedium && index === 50)
    ) {
      return <MainDescGrid />;
    }

    if ((!isTablet && index === 26) ||
      (isTablet && !isDesktopMedium && index === 43) ||
      (isDesktopMedium && index === 66)
    ) {
      return (
        <GridImage
          src="/images/grid-photo-2x2.png, /images/grid-photo-2x2@2.png 2x"
          sizeX={2}
          sizeY={2}
          zIndex={4}
        />
      );
    }

    if ((!isTablet && index === 28) ||
      (isTablet && !isDesktopMedium && index === 36) ||
      (isDesktopMedium && index === 76)
    ) {
      return (
        <GridImage
          src="/images/grid-photo-3x2.png, /images/grid-photo-3x2@2.png x2"
          sizeX={3}
          sizeY={2}
          zIndex={3}
        />
      );
    }

    if ((isTablet && !isDesktopMedium && index === 6) ||
      (isDesktopMedium && index === 10)
    ) {
      return signer && <LoggedPanel />;
    }

    return null;
  }, [
    isDesktopMedium,
    isTablet,
    signer,
  ]);

  return (
    <>
      {!isTablet && signer && <LoggedPanel />}
      <GridStyled>
        {gridElements.map((gridBox, index) => (
          <PaintFaceElement
            splash={splashOn}
            key={`pf-grid-${index + 1}`}
            active={activeGridBoxes.includes(index)}
            shouldFadeOut={!holdModeOn}
            erased={facesErased}
            renders={getFixedSrc(index)}
            xSpan={index === 30 ? 3 : 1}
            ySpan={index === 30 ? 3 : 1}
            currentPFP={currentPfpIndex}
            setNextPFP={setNextPfpIndex}
          />
        ))}
      </GridStyled>
    </>
  );
}
